import {
  REACT_APP_COGNITO_CLIENT_ID,
  checkAuthValid,
  getCognitoCookies,
  getMe,
  cleanSession,
  checkPathPermission,
} from '@commons';
import { FlatUser } from '@freelancelabs/teoreme-commons';
import { ReactComponent as Welcome } from 'assets/welcome.svg';
import { fetchUserAttributes, signInWithRedirect } from 'aws-amplify/auth';
import { Box, Button, Flex, Link, SpinnerBox, Text } from 'components/ui';
import { MicrosoftIcon } from 'components/ui/icons';
import { useBreadCrumb } from 'hooks/breadCrumb';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouterStore } from 'store';

export const LoginPage = () => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isFetchingGetMe, setIsFetchingGetMe] = React.useState(false);
  const [checkCookies, setCheckCookies] = React.useState(true);
  const [timeToCheck, setTimeToCheck] = React.useState(0);
  const [previousCookies, setPreviousCookies] =
    React.useState(getCognitoCookies());
  // use this after login on redirect on targetLocation
  const { targetlocation, updateRouteStoreState, resetRouteStoreSates } =
    useRouterStore();
  const history = useHistory();
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Connexion`,
      path: '/login',
    },
  ]);

  const loginWithAzure = async (providerName: string): Promise<void> => {
    setIsFetching(true);
    //Clean session before connect
    await cleanSession();
    try {
      sessionStorage.isLogin = 'true';
      await signInWithRedirect({
        provider: {
          custom: providerName,
        },
      });
    } catch (e) {
      sessionStorage.removeItem('isLogin');
    }
  };
  const redirectOnTarget = (defaultPathname: string, user?: FlatUser) => {
    let targetPath = targetlocation || sessionStorage?.targetLocation;
    if (targetPath === 'undefined') {
      targetPath = undefined;
    }
    sessionStorage.removeItem('targetLocation');
    if (targetPath) {
      if (checkPathPermission(user, targetPath)) {
        resetRouteStoreSates({});
        history?.push(targetPath);
      } else {
        resetRouteStoreSates({});
        history?.push(defaultPathname);
      }
    } else {
      history?.push(defaultPathname);
    }
  };
  const checkPermission = async () => {
    if (!isFetchingGetMe) {
      setIsFetchingGetMe(true);
      const me = await getMe();
      try {
        let resp = await fetchUserAttributes();
        // console.log('!!! checkPermission !!! resp', resp);
        let userAttributes = JSON.parse(resp?.identities as any);
        const isPartOfCompany =
          userAttributes?.[0]?.providerName === 'azure-entra-id-freelancecom';
        //console.log('!!! checkPermission !!! userAttributes', userAttributes);
        const havePermissionManager = me?.apps?.find(
          app => app?.accessId === 'CONNECTEED_MANAGER'
        );
        //console.log('!!! checkPermission !!! isPartOfCompany', isPartOfCompany);
        setIsFetching(false);
        if (isPartOfCompany) {
          if (me) {
            if (havePermissionManager) {
              let cookieKey = `CognitoIdentityServiceProvider.${REACT_APP_COGNITO_CLIENT_ID}.${localStorage?.cognitoUserId}.cookieFrom`;
              document.cookie = `${cookieKey}=MANAGER; domain=freelance.com`;
              sessionStorage.removeItem('isLogin');
              redirectOnTarget('/dashboard', me);
            }
          } else {
            let cookieKey = `CognitoIdentityServiceProvider.${REACT_APP_COGNITO_CLIENT_ID}.${localStorage?.cognitoUserId}.cookieFrom`;
            document.cookie = `${cookieKey}=MANAGER; domain=freelance.com`;
            sessionStorage.removeItem('isLogin');
            history?.push('/get-access');
          }
        } else {
          sessionStorage.clear();
          cleanSession();
        }
      } catch (e) {
        //console.log('!!! checkPermission !!! ERROR', e);
      }
      //console.log('!!! checkPermission !!! ME', me);
    } else {
      //console.log('WAIT ME');
    }
  };

  React.useEffect(() => {
    const intervalId = setInterval(async () => {
      const currentCookies = getCognitoCookies();
      if (currentCookies?.length > 0) {
        if (currentCookies !== previousCookies) {
          setPreviousCookies(currentCookies);
          setCheckCookies(false);
          const isAuth = checkAuthValid();
          if (isAuth) {
            setIsFetching(true);
            await checkPermission();
          }
        }
      } else {
        //after 10 sec stop check
        if (timeToCheck > 10) {
          setTimeToCheck(0);
          cleanSession();
        } else {
          setTimeToCheck(timeToCheck + 1);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [checkCookies, previousCookies, timeToCheck]);
  return (
    <Flex height={'100vh'} alignItems="center" justifyContent="center">
      {sessionStorage?.isLogin === 'true' && <SpinnerBox />}
      <Flex
        hidden={sessionStorage?.isLogin === 'true'}
        alignItems="center"
        width="100%"
        maxWidth={1000}
      >
        <Box flex={1}>
          <Text variant="h1" fontSize={32} mb={50}>
            Content de vous revoir
          </Text>
          <Button
            isLoading={isFetching}
            data-test="login-azure-button"
            onClick={() => loginWithAzure('azure-entra-id-freelancecom')}
            type={'submit'}
          >
            <Flex>
              Se connecter avec Microsoft Azure
              <Box ml={10}>
                <MicrosoftIcon />
              </Box>
            </Flex>
          </Button>
        </Box>
        <Flex alignItems="center" flexDirection="column" flex={1}>
          <Welcome height="250px" width="500px" />
          {false && (
            <Box display="flex" flexDirection="row">
              <Text>Vous avez besoin d'aide ?</Text>
              <Link marginTop={1} marginLeft={2}>
                Contactez-nous
              </Link>
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
