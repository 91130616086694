import {
  additionalActivityFindMany,
  createCreditNote,
  displayMonth,
  getEstablishmentName,
  getFullName,
  invoiceSendReminder,
  INVOICE_STATUS_SELECT,
  isAdditionalActivityInvoice,
  isMileStoneInvoice,
  isStandByDutyInvoice,
  isTimeSpentInvoice,
  queryClient,
  sageCreateInvoice,
  useInvoiceFindMany,
  resolveContractorId,
  getCustomerEstGescomCode,
  getProviderEstGescomCode,
  isExpenseInvoice,
  formatInvoiceOrAdditionalActivitySort,
  exportFileXlxs,
  INVOICE_FOREIGN_SEARCH_FIELDS,
} from '@commons';
import {
  EBillingType,
  ECraStatus,
  EExportModel,
  EInvoiceDocumentType,
  EInvoiceStatus,
  EInvoiceType,
  EMissionCraValidatedBy,
  IAdditionalActivity,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showDialogModal } from 'components/modals/DialogModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomBDLModal';
import { UserSelect } from 'components/selects/UserSelect';
import {
  BlocInformation,
  Box,
  Button,
  Container,
  CustomToolTip,
  Flex,
  Input,
  Itemize,
  LabelField,
  Link,
  Menu,
  MenuItem,
  Spinner,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  FastCashIcon,
  MoreFilterIcon,
  RefreshIcon,
  ValidateIcon,
} from 'components/ui/icons';
import {
  canDisplayPoviderCreateCreditNoteButton,
  canDisplayPoviderInvoiceCreateInGescomButton,
  canDisplayPoviderInvoiceResendButton,
  canDisplayPoviderInvoiceShowCreditNoteButton,
  isValidableInvoice,
} from 'helpers';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import { snakeCase } from 'lodash';
import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useInvoiceProviderListStore } from 'store';
import { Theme } from 'styles';
import { getObjectFilterInvoice, getSearchInKeysText } from '../../helpers';
import { InvoiceStatusEnum } from '../../types';
import { SideBar } from 'components/ui/BootStrap/SideBar';
import { InvoicesFiltersList } from '../../InvoicesFiltersList';
import { useGlobalStore } from 'store/global';
import EXPORT_IMG from 'assets/images/export_excel_tutorial.png';
import { StaticSelect } from 'components/selects/StaticSelect';
//import { StaticMultiSelect } from 'components/selects/StaticMultiSelect';

type Item = Itemize<IJoinedInvoice>;
const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);

INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';
const Tabs = (
  <>
    <Tab variant="secondary" href={'/orders/invoices/provider/time-spent/all'}>
      Tous
    </Tab>
    <Tab
      variant="secondary"
      href={'/orders/invoices/provider/time-spent/awaiting'}
    >
      En attente
    </Tab>
    <Tab
      variant="secondary"
      href={'/orders/invoices/provider/time-spent/to-be-validated'}
    >
      A valider
    </Tab>
    <Tab
      variant="secondary"
      href={'/orders/invoices/provider/time-spent/validated'}
    >
      Validées
    </Tab>
    <Tab variant="secondary" href={'/orders/invoices/provider/time-spent/paid'}>
      Payées
    </Tab>
    <Tab
      variant="secondary"
      href={'/orders/invoices/provider/time-spent/cancelled'}
    >
      Annulées
    </Tab>
  </>
);
const columns = (
  status:
    | 'all'
    | 'awaiting'
    | 'to-be-validated'
    | 'validated'
    | 'archived'
    | 'cancelled'
    | 'paid',
  ReSendButton: any,
  invoiceTypeText: any,
  history: any,
  showMessage: any
) =>
  [
    {
      key: 'displayReference',
      sortKey: status !== 'all' && 'mission.displayReference',
      text: 'REF.MISSION',
      render: (item: IJoinedInvoice) => {
        const deadline = item?.paymentDeadline as number;
        return (
          <ALink
            href={`/delivery/missions/${item?.mission.reference}`}
            target="_blank"
          >
            {status === 'to-be-validated' &&
            /*@ts-ignore*/
            item?.hasFastCash ? (
              <Box>
                <a data-for={item.uuid} data-tip="" data-iscapture="true">
                  <ReactTooltip
                    id={item.uuid}
                    place={'bottom'}
                    variant={'dark'}
                    float={false}
                    style={{
                      backgroundColor:
                        deadline > 10
                          ? Theme.colors.warning.default
                          : Theme.colors.error.default,
                    }}
                  >
                    <Text color="white" fontSize={11}>
                      Fast cash {deadline} jours
                    </Text>
                  </ReactTooltip>
                  <Box mr={20}>
                    <FastCashIcon
                      deaddline={deadline}
                      fill={
                        deadline > 10
                          ? Theme.colors.warning.default
                          : Theme.colors.error.default
                      }
                    />
                  </Box>
                </a>
              </Box>
            ) : (
              <Box ml={status === 'to-be-validated' ? '40px' : 0}> </Box>
            )}
            <CustomToolTip
              text={item?.mission?.description}
              id={item?.uuid + '_description'}
            >
              <Text>{item.mission.displayReference}</Text>
            </CustomToolTip>
          </ALink>
        );
      },
      //render: (item: IJoinedInvoice) => item.mission.displayReference,
    },
    {
      key: 'structure',
      sortKey: status !== 'all' && 'mission.sage.sucture',
      text: 'STRU',
      render: (item: IJoinedInvoice) => item?.mission?.sage?.structure,
    },
    {
      key: 'month',
      sortKey: status !== 'all' && 'month',
      text: 'MOIS',
      render: (item: IJoinedInvoice) => {
        //@ts-ignore
        const normalizedDate = item?.normalizedDate;
        if (item?.mission?.billing?.type === EBillingType.FLAT_RATE) {
          if (normalizedDate) {
            return `${
              new Date(normalizedDate as Date)?.getUTCMonth() + 1
            }/${new Date(normalizedDate as Date)?.getUTCFullYear()}`;
          } else {
            return 'N/A';
          }
        }

        return `${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
          item?.month as Date
        )?.getUTCFullYear()}`;
      },
    },
    {
      key: 'invoiceType',
      sortKey: status !== 'all' && 'invoiceType',
      text: 'TYPE',
      render: (item: IJoinedInvoice) => {
        const isMarginInvoice = item?.isMarginInvoice ? ' (marge)' : '';
        return invoiceTypeText(item.invoiceType) + isMarginInvoice;
      },
    },
    {
      key: 'statusChangedAt',
      sortKey: status !== 'all' && 'statusChangedAt',
      text: 'REÇUE LE',
      hidden: status !== 'to-be-validated',
      render: (item: IJoinedInvoice) =>
        //@ts-ignore
        item?.statusChangedAt && item?.statusChangedAt.toLocaleDateString(),
    },
    {
      key: 'estCustomer',
      sortKey: status !== 'all' && 'estCustomer.businessName',
      text: 'ÉTABLIS. CLIENT',
      render: (item: IJoinedInvoice) => (
        <CustomToolTip
          text={<Box>{getCustomerEstGescomCode(item)}</Box>}
          id={item?.uuid + '_structCustomer'}
        >
          <ALink
            href={`/clients/establishments/${item?.estCustomer?.uuid}`}
            target="_blank"
          >
            {getEstablishmentName(item.estCustomer)}
          </ALink>
        </CustomToolTip>
      ),
    },
    {
      key: 'estProvider',
      sortKey: status !== 'all' && 'estProvider.businessName',
      text: 'ÉTABLIS. FOURN.',
      render: (item: IJoinedInvoice) => (
        <CustomToolTip
          text={<Box>{getProviderEstGescomCode(item)}</Box>}
          id={item?.uuid + '_structProvider'}
        >
          <ALink href={`/providers/${item?.estProvider?.uuid}`} target="_blank">
            {getEstablishmentName(item.estProvider)}
          </ALink>
        </CustomToolTip>
      ),
    },
    {
      key: 'contractor',
      sortKey: status !== 'all' && 'contractor.firstName',
      text: 'INTERVENANT',
      render: (item: IJoinedInvoice) => (
        <CustomToolTip
          text={<Box>{resolveContractorId(item)}</Box>}
          id={item?.uuid + '_structContractor'}
        >
          <ALink
            href={`/providers/contractors/${item?.contractor?.uuid}`}
            target="_blank"
          >
            {getFullName(item?.contractor)}
          </ALink>
        </CustomToolTip>
      ),
    },
    {
      key: 'totalBeforeTaxes',
      sortKey: status !== 'all' && 'totalBeforeTaxes',
      text: `Montant HT`,
      //hidden: status === 'awaiting',
      render: (item: IJoinedInvoice) =>
        `${item?.totalBeforeTaxes?.toFixed(2) || 'N/A'} €`,
    },
    {
      key: 'PAID',
      text: `PAYÉ`,
      align: 'left',
      hidden: !['cancelled', 'archived'].includes(status),
      render: (item: IJoinedInvoice) => {
        let paymentDate = item?.sage?.paymentDetails?.paymentDate;
        if (paymentDate) {
          return (
            <CustomToolTip
              text={`Virement émis le :  ${paymentDate?.toLocaleString()}`}
              id={item?.uuid + '_paid'}
            >
              <Flex>
                <CheckIcon
                  fontSize={25}
                  fill={Theme?.colors?.success?.default}
                />
              </Flex>
            </CustomToolTip>
          );
        } else {
          return (
            <Flex>
              <CloseIcon fontSize={20} fill={Theme?.colors?.error?.default} />
            </Flex>
          );
        }
      },
    },
    {
      key: 'status',
      sortKey: status !== 'all' && 'status',
      text: 'STATUT',
      hidden: ['to-be-validated', 'validated', 'paid'].includes(status),
      render: (item: IJoinedInvoice) => {
        const status = item.status;
        const statusChangedAt = item?.statusChangedAt;
        let variantColor = 'grey';
        let text = '';
        switch (status) {
          case EInvoiceStatus.PROJECTED:
            variantColor = 'grey';
            text = 'Prévisionnelle';
            break;
          case EInvoiceStatus.TO_BE_FILLED:
            variantColor = 'grey';
            text = 'A saisir';
            break;
          case EInvoiceStatus.TO_BE_SUBMITTED:
            variantColor = 'grey';
            text = 'Non soumise';
            break;
          case EInvoiceStatus.TO_BE_VALIDATED:
            variantColor = 'warning';
            text = `Transmise le : ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.VALIDATED:
            variantColor = 'success';
            text = `Validé  ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.ARCHIVED:
            variantColor = 'grey';
            text = 'Archivé';
            break;
          case EInvoiceStatus.REJECTED:
            variantColor = 'error';
            text = `Refusé le ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.CANCELLED:
            variantColor = 'error';
            text = `Annulé le ${item?.statusChangedAt?.toLocaleDateString()}`;
            break;
          case EInvoiceStatus.PAID:
            variantColor = 'success';
            text = `Payée ${
              item?.sage?.paymentDetails?.paymentDate
                ? '- Virement émis le : ' +
                  item?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()
                : ''
            }`;
            break;
          default:
            variantColor = 'grey';
        }

        return (
          <Flex>
            <Status
              //@ts-ignore
              variantColor={variantColor}
            >
              {text}
            </Status>
          </Flex>
        );
      },
    },
    {
      key: 'statusChangedAt',
      sortKey: status !== 'all' && 'statusChangedAt',
      text: `DATE DE VALIDATION`,
      hidden: status !== 'validated',
      render: (item: IJoinedInvoice) => {
        const statusChangedAt = item?.statusChangedAt;
        return statusChangedAt && statusChangedAt.toLocaleDateString();
      },
    },
    {
      key: 'paymentDate',
      sortKey: status !== 'all' && 'sage.paymentDetails.paymentDate',
      text: `DATE DE PAIEMENT`,
      hidden: status !== 'paid',
      render: (item: IJoinedInvoice) => {
        return item?.sage?.paymentDetails?.paymentDate?.toLocaleDateString();
      },
    },
    {
      key: 'dueDate',
      sortKey: status !== 'all' && 'dueDate',
      text: `DATE D'ÉCHÉANCE`,
      hidden: status !== 'validated',
      render: (item: IJoinedInvoice) =>
        `${item?.dueDate?.toLocaleDateString() ?? ''} `,
    },
    {
      key: 'deliveryOrder',
      //sortKey: 'invoice',
      text: 'BDL FOURNISSEUR',
      hidden:
        status === 'awaiting' || status === 'to-be-validated' ? false : true,
      render: (
        item: IJoinedInvoice & { additionalActivity?: IAdditionalActivity }
      ) => {
        const notValidatedStatus = [
          'TO_BE_FILLED',
          'TO_BE_SUBMITTED',
          'REJECTED',
          'TO_BE_VALIDATED',
        ];
        const textType = isTimeSpentInvoice(item?.invoiceType)
          ? 'du CRA'
          : isStandByDutyInvoice(item?.invoiceType)
            ? 'de la prestation complémentaire'
            : isExpenseInvoice(item?.invoiceType)
              ? 'du frais'
              : isMileStoneInvoice(item?.invoiceType)
                ? 'du jalon'
                : 'N/A';
        if (
          isTimeSpentInvoice(item?.invoiceType)
          //notValidatedStatus?.includes(item?.cra?.state)
        ) {
          if (item?.cra?.state === ECraStatus?.TO_BE_VALIDATED) {
            return (
              <Status variantColor="warning">
                En attente de la validation du CRA{' '}
                {item?.cra?.craValidatedBy ===
                  EMissionCraValidatedBy?.CUSTOMER_CONTACT && 'par le client'}
                .
              </Status>
            );
          } else {
            if (
              item?.sage?.documentType ===
                EInvoiceDocumentType.DELIVERY_ORDER ||
              item?.sage?.documentType === EInvoiceDocumentType.INVOICE
            ) {
              return <Status variantColor="success">Créé dans GESCOM</Status>;
            } else {
              return 'N/A';
            }
          }
        }
        if (
          isAdditionalActivityInvoice(item?.invoiceType) &&
          //@ts-ignore
          notValidatedStatus?.includes(item?.additionalActivity?.status)
        ) {
          return (
            <Status variantColor="warning">
              En attente de la validation {textType} .
            </Status>
          );
        }
        if (item?.status !== EInvoiceStatus.PROJECTED) {
          if (!item?.sage) {
            return <Status variantColor="grey">À créer dans GESCOM</Status>;
          }
          if (!item?.sage?.documentType) {
            return <Status variantColor="grey">À créer dans GESCOM</Status>;
          }
          if (
            item?.sage?.documentType === EInvoiceDocumentType.DELIVERY_ORDER ||
            item?.sage?.documentType === EInvoiceDocumentType.INVOICE
          ) {
            return <Status variantColor="success">Créé dans GESCOM</Status>;
          }
        }
      },
    },
    {
      key: 'sageInvoice',
      text: 'FACTURE GESCOM',
      hidden:
        status === 'paid' || status === 'validated' || status === 'archived'
          ? false
          : true,
      render: (item: IJoinedInvoice) => {
        if (!item?.sage?.documentType) {
          return (
            <Status variantColor="error">
              BDL fournisseur à créer dans GESCOM
            </Status>
          );
        }
        if (item?.sage?.documentType === EInvoiceDocumentType.DELIVERY_ORDER) {
          return <Status variantColor="grey">à Créé dans GESCOM</Status>;
        }
        if (item?.sage?.documentType === EInvoiceDocumentType.INVOICE) {
          return (
            <ALink
              href={`/orders/invoices/provider/time-spent/${item?.uuid}`}
              target="_blank"
            >
              <Status variantColor="success">{item?.sage?.invoiceId}</Status>
            </ALink>
          );
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: (
        item: IJoinedInvoice & { additionalActivity?: IAdditionalActivity }
      ) => {
        const icon = <EyeIcon style={{ marginTop: 4 }} />;
        const invoiceId = item?.uuid;
        const missionRef = item?.mission?.reference;
        const contractorId = item?.contractor?.uuid;
        const invoiceFile = item?.invoiceFile;
        return (
          <Flex alignItems="center">
            <ALink href={`/orders/invoices/provider/time-spent/${invoiceId}`}>
              <Link iconLeft={icon}></Link>
            </ALink>
            {canDisplayPoviderInvoiceResendButton(item) && (
              <Flex mr={'5px'} mt={'10px'}>
                <ReSendButton invoice={item} />
              </Flex>
            )}
            {isValidableInvoice(item) && (
              <Flex mr={'5px'} mt={'10px'} mb={'10px'}>
                {(item?.sage ||
                  (!item?.sage && item?.mission?.isMandateMode)) && (
                  <>
                    <ValidateIcon fill={'red !important'} />
                    <ALink
                      href={`/orders/invoices/provider/time-spent/${invoiceId}`}
                      params={{ hookStore: 'useInvoiceProviderListStore' }}
                    >
                      <Link>Valider</Link>
                    </ALink>
                  </>
                )}
              </Flex>
            )}
            <Menu
              align="right"
              menuItems={
                <>
                  {invoiceFile && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showDisplayPdfModal({
                            fileLocation: invoiceFile.fileLocation,
                            //@ts-ignore
                            fileName: invoiceFile.fileName,
                          })
                        }
                        iconLeft={icon}
                      >
                        Voir la facture
                      </Link>
                    </MenuItem>
                  )}
                  {canDisplayPoviderCreateCreditNoteButton(item) && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showDialogModal({
                            title: "Création d'un avoir",
                            text: (
                              <Box>
                                <BlocInformation mb={10}>
                                  Cette fonctionnalité est à utiliser uniquement
                                  si la facture fournisseur doit être corrigée
                                  en raison d'une erreur lié à l'absence de
                                  services complémentaires. Pour toute autre
                                  modification touchant cette facture (comme le
                                  nombre de jours à facturer par exemple), merci
                                  d'utiliser la fonctionnalité de
                                  Correction/Refus de CRA.
                                </BlocInformation>
                                <Text mb={20} variant="p">
                                  Vous êtes sur le point de créer un avoir à la
                                  facture fournisseur suivante :
                                </Text>
                                <LabelField
                                  label="Mission :"
                                  value={item?.mission?.displayReference}
                                />
                                <LabelField
                                  label="Mois concerné :"
                                  value={displayMonth(item?.month as Date)}
                                />
                                <Text mt={10} variant="b">
                                  Cette action passera la facture actuelle au
                                  statut "annulée" et générera une facture
                                  d'avoir ainsi qu'une nouvelle facture
                                  fournisseur au statut "à saisir" pour ce mois.
                                </Text>
                              </Box>
                            ),
                            cancelLabel: 'Annuler',
                            confirmLabel: "Créer l'avoir",
                            beforeValidation: async () => {
                              try {
                                const responseCreditNote =
                                  await createCreditNote({
                                    originalInvoice: item?.uuid,
                                  });
                                queryClient?.refetchQueries({
                                  queryKey: ['Invoices'],
                                  type: 'active',
                                });
                                showMessage(
                                  'success',
                                  "L'avoir à bien été crée"
                                );
                                history?.push(
                                  `/orders/invoices/provider/time-spent/${responseCreditNote?.creditNote?.uuid}`
                                );
                              } catch (e) {
                                //
                              }
                            },
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Créer un avoir
                      </Link>
                    </MenuItem>
                  )}
                  {canDisplayPoviderInvoiceShowCreditNoteButton(item) && (
                    <MenuItem>
                      {item?.creditNote?.invoiceFile?.fileLocation ? (
                        <Link
                          iconLeft={<EyeIcon />}
                          onClick={() =>
                            showDisplayPdfModal({
                              //@ts-ignore
                              fileLocation:
                                item?.creditNote?.invoiceFile?.fileLocation,
                              //@ts-ignore
                              fileName: item?.creditNote?.invoiceFile?.fileName,
                            })
                          }
                        >
                          Voir la facture d'avoir
                        </Link>
                      ) : (
                        <Link iconLeft={<EyeIcon />} isDisabled>
                          Aucune facture généré
                        </Link>
                      )}
                    </MenuItem>
                  )}
                  <MenuItem>
                    <ALink href={`/delivery/missions/${missionRef}`}>
                      <Link iconLeft={icon}>Voir la mission</Link>
                    </ALink>
                  </MenuItem>
                  <MenuItem>
                    <ALink href={`/providers/contractors/${contractorId}`}>
                      <Link iconLeft={icon}>Voir la fiche intervenant</Link>
                    </ALink>
                  </MenuItem>
                  {isValidableInvoice(item) && !item?.sage && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showCreateGescomBDLModal({
                            invoice: item,
                            cra: item?.cra,
                            mission: item?.mission,
                            delivreyOrders: {
                              bdlProvider: false,
                              bdlCustomer: true,
                            },
                            customerReference:
                              item?.mission?.customer?.purchaseOrder
                                ?.reference || '',
                            refetchQueryKey: 'Invoices',
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Créer dans GESCOM
                      </Link>
                    </MenuItem>
                  )}

                  {canDisplayPoviderInvoiceCreateInGescomButton(
                    item,
                    status
                  ) && (
                    <MenuItem>
                      <Link
                        onClick={async () =>
                          await showDialogModal({
                            title:
                              'Êtes-vous sûr.e de vouloir valider cette facture ?',
                            text: 'Les données de facturation saisies dans le formulaire seront envoyées en l’état à GESCOM pour comptabilisation et le bon de livraison fournisseur sera remplacé par la facture fournisseur GESCOM.',
                            confirmLabel: 'Valider',
                          }).then(async action => {
                            if (action) {
                              try {
                                await sageCreateInvoice({
                                  invoice: item?.uuid,
                                });
                                showMessage(
                                  'success',
                                  'La facture à été créé dans GESCOM'
                                );
                                queryClient.refetchQueries({
                                  queryKey: ['Invoices'],
                                  type: 'active',
                                });
                              } catch (e) {
                                //
                              }
                            }
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Créer dans GESCOM
                      </Link>
                    </MenuItem>
                  )}
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];

export const ProviderTimeSpentList = () => {
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const [isLoadingExport, setIsLoadingExport] = React.useState(false);
  const [items, setItems] = React.useState<IJoinedInvoice[]>([]);
  const history = useHistory();
  const { filter: filterParams } = useParams<{
    filter:
      | 'all'
      | 'awaiting'
      | 'validated'
      | 'to-be-validated'
      | 'archived'
      | 'paid';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'Fournisseurs',
        path: '/orders/invoices',
      },
      {
        label: 'Factures',
        path: '/orders/invoices',
      },
      {
        label: 'Factures fournisseurs',
        path: '/orders/invoices/provider/time-spent/awaiting',
      },
      {
        label:
          filterParams === 'awaiting'
            ? 'En attente'
            : filterParams === 'to-be-validated'
              ? 'À valider'
              : filterParams === 'archived'
                ? 'Archivés'
                : 'validés',
        path: '/orders/invoices/provider/time-spent/' + filterParams,
      },
    ]
  );

  const {
    initialSate,
    updateStore,
    resetStore,
    getNbActiveFilters,
    startDate,
    endDate,
    sortedBy,
    order,
    selectedStatus,
    selectedType,
    searchQuery,
    page,
    accountManagerId,
    commercialSelected,
    structureSelected,
    clientSelected,
    providerSelected,
    multiClientSelected,
    multiProviderSelected,
    contractorSelected,
    //invoiceTypeSelected,
    invoiceDate,
    searchInKeys,
    createdFromGescomFlow,
    locked,
    limit,
  } = useInvoiceProviderListStore();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const additionalFiltersFields = [
    'selectedType',
    'commercialSelected',
    'structureSelected',
    'multiClientSelected',
    'multiProviderSelected',
    'contractorSelected',
    'invoiceDate',
  ];
  const {
    data: invoiceQuery,
    status,
    isFetching,
  } = useInvoiceFindMany({
    filterObject: getObjectFilterInvoice(
      {
        debouncedFilterQuery,
        startDate,
        endDate,
        filterUrl: snakeCase(
          filterParams
        ).toLocaleUpperCase() as InvoiceStatusEnum,
        selectedStatus,
        selectedType,
        accountManagerId,
        commercialSelected,
        structureSelected,
        clientSelected,
        providerSelected,
        multiClientSelected,
        multiProviderSelected,
        contractorSelected,
        invoiceDate,
        searchInKeys,
        locked,
        createdFromGescomFlow,
      },
      'PROVIDER'
    ),
    limit: limit,
    skip: limit * page,
    sort: formatInvoiceOrAdditionalActivitySort(
      `${
        filterParams === 'to-be-validated'
          ? '-hasFastCash fastCashDeadline'
          : ''
      } ${
        sortString?.includes('month')
          ? `${sortString?.replaceAll('month', 'normalizedDate')} uuid`
          : sortString
      }`
    ),
  });
  const onExportToExcel = async () => {
    setIsLoadingExport(true);
    try {
      const exportResponse = await exportFileXlxs({
        model: EExportModel.INVOICE,
        query: {
          filter: getObjectFilterInvoice(
            {
              debouncedFilterQuery,
              startDate,
              endDate,
              filterUrl: snakeCase(
                filterParams
              ).toLocaleUpperCase() as InvoiceStatusEnum,
              selectedStatus,
              selectedType,
              accountManagerId,
              commercialSelected,
              structureSelected,
              clientSelected,
              providerSelected,
              multiClientSelected,
              multiProviderSelected,
              contractorSelected,
              invoiceDate,
              searchInKeys,
              locked,
              createdFromGescomFlow,
            },
            'PROVIDER'
          ),
          //fields:[],
          // limit: limit,
          // skip: limit * page,
          // sort: sortString,
        },
      });
      const uuid = exportResponse?.taskUuid;
      if (Array.isArray(tasksInProgress)) {
        updateGlobalStore({ tasksInProgress: [...tasksInProgress, uuid] });
      } else {
        updateGlobalStore({ tasksInProgress: [uuid] });
      }
      showDialogModal({
        title: 'Export excel en cours',
        text: (
          <Box>
            <Text>
              La création de votre export excel est en cours. Cette action peut
              prendre quelques minutes. Vous pouvez suivre l'état de progression
              en cliquant sur l'icone ci-dessous.
            </Text>
            <img src={EXPORT_IMG} />
          </Box>
        ),
        confirmLabel: "J'ai compris",
      });
      setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };

  const loading = status === 'pending';
  const totalCount = invoiceQuery?.totalCount || 0;

  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateStore({ page: 0, sortedBy, order });
    },
    []
  );

  const showMessage = useShowMessage();

  const invoiceTypeText = (invoiceType: EInvoiceType) => {
    if (invoiceType === EInvoiceType?.PROVIDER) {
      return 'Prest. std.';
    }
    if (invoiceType === EInvoiceType?.PROVIDER_STAND_BY_DUTY) {
      return 'Prest. comp.';
    }
    if (invoiceType === EInvoiceType?.PROVIDER_EXPENSE) {
      return 'Frais';
    }
    if (invoiceType === EInvoiceType?.PROVIDER_MILESTONE) {
      return 'Jalon';
    }
  };
  const ReSendButton = ({ invoice }: { invoice: IJoinedInvoice }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        invoiceSendReminder({
          uuid: invoice.uuid,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success') {
      showMessage('success', 'Votre relance a bien été envoyée');
    }
    if (status === 'error') {
      showMessage(
        'error',
        'Suite à une erreur technique, votre relance n’a pas pu être envoyée. Veuillez réessayer'
      );
    }

    return (
      <Link
        style={{ paddingBottom: 15 }}
        iconLeft={<RefreshIcon />}
        onClick={() => onResend()}
      >
        Relancer
      </Link>
    );
  };
  const handleMergeInvoiceProviderAndAA = async (
    invoices: IJoinedInvoice[]
  ) => {
    const aaUuid = invoices?.filter(i => {
      return i?.additionalActivity;
    });
    const findAA = aaUuid?.length > 0;
    try {
      const aa = findAA
        ? await additionalActivityFindMany({
            filterObject: {
              uuid: { $in: aaUuid?.map(i => i?.additionalActivity) },
            },
          })
        : undefined;
      let items = invoiceQuery?.invoices?.map(invoice => ({
        key: invoice.uuid || '',
        ...invoice,
      }));
      items = items?.map((invoicesProvider: any) => ({
        ...invoicesProvider,
        additionalActivity: findAA
          ? aa?.additionalActivities?.find(
              a => a?.uuid === invoicesProvider?.additionalActivity
            )
          : undefined,
      }));
      //@ts-ignore
      setItems(items);
    } catch (e) {
      //
    }
  };
  React.useEffect(() => {
    if (invoiceQuery) {
      handleMergeInvoiceProviderAndAA(invoiceQuery?.invoices);
    }
  }, [invoiceQuery]);

  React.useEffect(() => {
    updateStore({ page: 0 });
  }, [filterParams]);

  React.useEffect(() => {
    updateStore({
      invoiceToBeValidated: items?.filter(i => isValidableInvoice(i as any)),
      page: page,
    });
  }, [items]);
  return (
    <>
      <Header
        tabs={Tabs}
        actions={
          <Button isLoading={isLoadingExport} onClick={() => onExportToExcel()}>
            Exporter
          </Button>
        }
      >
        <Text variant="h1">Factures fournisseurs</Text>
      </Header>
      <Container p="20px 0">
        <Flex
          display={'inline-flex'}
          justifyContent={'flex-start'}
          flexWrap={'wrap'}
          m="10px 0"
          width={1 / 1}
        >
          <Flex width={1 / 1} mb={10}>
            <Box width={7 / 12} pr={'5px'} pt={'5px'}>
              <Flex>
                <Box width={3 / 10}>
                  <StaticSelect
                    isFieldSet
                    isClearable={false}
                    options={INVOICE_FOREIGN_SEARCH_FIELDS}
                    value={searchInKeys?.[0]?.value}
                    components={{ IndicatorSeparator: () => null }}
                    onChange={value =>
                      updateStore({
                        searchInKeys: [
                          INVOICE_FOREIGN_SEARCH_FIELDS?.find(
                            e => e?.value === value
                          ) as any,
                        ],
                      })
                    }
                  />
                </Box>
                <Box width={7 / 10} ml={'-5px'}>
                  <Input
                    isFieldSet
                    mr={20}
                    isFullWidth
                    data-cy="search-estaCustomer-list"
                    type="search"
                    name="search"
                    placeholder={
                      ('Rechercher dans : ' +
                        getSearchInKeysText(searchInKeys, true)) as string
                    }
                    icon={
                      <SideBar
                        title="Filtres de facture"
                        customLinkIcon={
                          <MoreFilterIcon
                            style={{ cursor: 'pointer' }}
                            fill={Theme?.colors?.grey?.default}
                          />
                        }
                        buttonType="icon"
                        buttonText=" "
                      >
                        <InvoicesFiltersList
                          filterInvoiceType="PROVIDER"
                          hookStore={useInvoiceProviderListStore}
                        />
                      </SideBar>
                    }
                    value={searchQuery}
                    onChange={(e: any) => {
                      updateStore({
                        page: 0,
                        searchQuery: e.target.value,
                      });
                    }}
                  />{' '}
                </Box>
              </Flex>
            </Box>
            {/* <Box width={5 / 12} pr={'5px'} pt={'5px'}>
              <StaticMultiSelect
                placeholder="Étendre la recherche dans ..."
                onChange={value => updateStore({ searchInKeys: value })}
                options={INVOICE_FOREIGN_SEARCH_FIELDS}
                values={searchInKeys}
              />
            </Box> */}
            <Box width={3 / 12} ml={20} mt={'5px'}>
              <UserSelect
                placeholder="Tous les chargés de compte"
                role="ACCOUNT_MANAGER"
                onChange={id => updateStore({ accountManagerId: id })}
                value={accountManagerId as string}
              />
            </Box>
            <Box width={2 / 12} ml={20}>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <SideBar
                  title="Filtres de facture"
                  customLinkIcon={<AddIcon />}
                  buttonType="link"
                  buttonText="Plus de filtres"
                >
                  <InvoicesFiltersList
                    filterInvoiceType="PROVIDER"
                    hookStore={useInvoiceProviderListStore}
                  />
                </SideBar>
                {initialSate === false &&
                  getNbActiveFilters(additionalFiltersFields) > 0 && (
                    <Box
                      backgroundColor={Theme?.colors?.primary?.default}
                      border={`1px solid ${Theme?.colors?.primary?.default}`}
                      borderRadius={'50%'}
                      height={15}
                      width={15}
                      ml={-2}
                      mt={-4}
                    >
                      <Flex justifyContent={'center'}>
                        <Text color="white" fontSize={'10px'}>
                          {' '}
                          {getNbActiveFilters(additionalFiltersFields)}
                        </Text>
                      </Flex>
                    </Box>
                  )}
                <Link
                  isDisabled={initialSate}
                  iconLeft={
                    <RefreshIcon
                      fill={
                        initialSate ? Theme?.colors?.grey?.default : undefined
                      }
                    />
                  }
                  ml={'5px'}
                  onClick={() => (!initialSate ? resetStore({}) : false)}
                >
                  Réinitialiser
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Itemize<IJoinedInvoice>>
            isLoading={isFetching}
            columns={columns(
              filterParams,
              ReSendButton,
              invoiceTypeText,
              history,
              showMessage
            )}
            items={items as Itemize<IJoinedInvoice>[]}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < limit ? totalCount : limit}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateStore({ page: page })}
          />
        )}
      </Container>
    </>
  );
};
