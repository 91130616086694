import {
  Row,
  Flex,
  Box,
  Button,
  Text,
  LabelField,
  Status,
} from 'components/ui';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import {
  IJoinedValidationRequest,
  IEstablishmentMinifiedUsers,
  EValidationStatus,
  IBankAccount,
} from '@freelancelabs/teoreme-commons';
import {
  getFullName,
  getHumanDate,
  getTradeNameSafe,
  useDownloadFile,
  useAdminValidationUpdateOne,
  queryClient,
  buildOneLineAddress,
  establishmentUseIban,
} from '@commons';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Theme } from 'styles';
import { Section } from 'components/Section';
import { electronicFormatIBAN } from 'ibantools';
import { showRejectModal } from './RejectModal';
import { useShowMessage } from 'hooks/useShowMessage';
type Props = ModalProps & {
  beforeValidation?: () => void;
  validationRequest: IJoinedValidationRequest & {
    establishment: IEstablishmentMinifiedUsers | undefined;
  };
  layer?: number;
};
const FileContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  z-index: 101;
`;
export const ValidateBankAccountModal = ({
  onResolve,
  isOpen,
  layer,
  validationRequest,
}: Props) => {
  const { mutateAsync: updateAdminValidation } = useAdminValidationUpdateOne();
  const showMessage = useShowMessage();
  const [isLoading, setIsLoading] = useState(false);
  const establishment = validationRequest?.establishment;
  const bankAccount = validationRequest?.payload?.bankAccount as IBankAccount;
  const estaProviderBankAccount = establishment?.provider?.bankAccount;
  const { data } = useDownloadFile(
    validationRequest?.payload?.bankAccount?.rib?.fileLocation
  );

  useEffect(() => {
    // use for download file
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        //
      };
    }
  }, [data]);

  let statusData;
  if (validationRequest?.status === EValidationStatus.PENDING) {
    statusData = {
      color: Theme?.colors?.warning?.default,
      variant: 'warning',
      text: 'En attente de validation',
    };
  }
  if (validationRequest?.status === EValidationStatus.REJECTED) {
    statusData = {
      color: Theme?.colors?.error?.default,
      variant: 'error',
      text: 'Rejeté',
    };
  }
  const onValidateRequest = async () => {
    setIsLoading(true);
    try {
      await updateAdminValidation({
        uuid: validationRequest?.uuid,
        status: EValidationStatus.VALIDATED,
      });
      showMessage('success', 'Demande validé');
    } catch (e) {
      setIsLoading(false);
    }
    setIsLoading(false);
    queryClient?.refetchQueries({ queryKey: ['admin-validation-search'] });
    onResolve();
  };
  const onRejectValidation = async (rejectReason: string) => {
    setIsLoading(true);
    try {
      await updateAdminValidation({
        uuid: validationRequest?.uuid,
        status: EValidationStatus.REJECTED,
        rejectReason: rejectReason,
      });
      showMessage('success', 'Demande Refusé');
    } catch (e) {
      setIsLoading(false);
    }
    setIsLoading(false);
    queryClient?.refetchQueries({ queryKey: ['admin-validation-search'] });
    onResolve();
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={1200}
      closeIcon
      layer={layer}
    >
      <Box mt={20}>
        <Text variant="h1">
          Demande de modification de coordonnées bancaires
        </Text>
        <Status variantColor={statusData?.variant as any}>
          {statusData?.text}
        </Status>
        <Flex>
          <Box width={'50%'}>
            <Section
              mt={20}
              variant="h3"
              title={'1.Informations sur la demande'}
            >
              <Row p={10} spacing={20}>
                <LabelField
                  value={getFullName(validationRequest?.createdBy as any)}
                  linkValue={`/providers/contacts/${validationRequest?.createdBy?.cognitoUserId}/information`}
                  label="Demande créé par"
                  underline
                />
                <LabelField
                  value={getHumanDate(validationRequest?.createdAt as Date)}
                  label="Date de la demande"
                  underline
                />
              </Row>
              <Row p={10} spacing={20}>
                <LabelField
                  value={getTradeNameSafe(
                    establishment as IEstablishmentMinifiedUsers
                  )}
                  linkValue={`/providers/establishments/${establishment?.uuid}/information`}
                  label="Établissement"
                  underline
                />
                <LabelField
                  value={establishment?.siret || establishment?.identifier}
                  label="SIRET/Identifiant"
                  underline
                />
              </Row>
              <Box>
                <LabelField
                  value={buildOneLineAddress(establishment?.address)}
                  label="Adresse de l'établissement"
                  underline
                />
              </Box>
            </Section>
            <Section
              mt={20}
              variant="h3"
              title={'2. Nouvelles coordonnées bancaires'}
            >
              {!establishmentUseIban(
                validationRequest?.establishment as IEstablishmentMinifiedUsers
              ) ? (
                <Text variant="p" p={10}>
                  L'établissement ne fait pas partie des pays utilisant un IBAN
                  , seul le fichier RIB est demandé.
                </Text>
              ) : (
                <Box border={'1px solid red'} marginRight={10} borderRadius={5}>
                  <Row p={10} spacing={20}>
                    <LabelField
                      value={bankAccount?.bankName || 'N/A'}
                      label="Nom de la banque"
                      underline
                    />
                    <LabelField
                      value={bankAccount?.owner || 'N/A'}
                      label="Titulaire du compte"
                      underline
                    />
                  </Row>
                  <Row p={10} spacing={20}>
                    <LabelField
                      value={
                        bankAccount?.iban
                          ? electronicFormatIBAN(bankAccount?.iban)
                          : 'N/A'
                      }
                      label="IBAN"
                      underline
                    />
                    <LabelField
                      value={bankAccount?.bic || 'N/A'}
                      label="BIC"
                      underline
                    />
                  </Row>
                </Box>
              )}
            </Section>
            <Section
              mt={20}
              variant="h3"
              title={'3. Coordonnées bancaires actuelles'}
            >
              {estaProviderBankAccount ? (
                <>
                  <Row p={10} spacing={20}>
                    <LabelField
                      value={estaProviderBankAccount?.bankName || 'N/A'}
                      label="Nom de la banque"
                      underline
                    />
                    <LabelField
                      value={estaProviderBankAccount?.owner || 'N/A'}
                      label="Titulaire du compte"
                      underline
                    />
                  </Row>
                  <Row p={10} spacing={20}>
                    <LabelField
                      value={
                        estaProviderBankAccount?.iban
                          ? electronicFormatIBAN(estaProviderBankAccount?.iban)
                          : 'N/A'
                      }
                      label="IBAN"
                      underline
                    />
                    <LabelField
                      value={estaProviderBankAccount?.bic || 'N/A'}
                      label="BIC"
                      underline
                    />
                  </Row>
                  <Row p={10} spacing={20}>
                    <LabelField
                      value={estaProviderBankAccount?.rib?.fileName || 'N/A'}
                      linkValue={
                        estaProviderBankAccount?.rib
                          ? `/file/${estaProviderBankAccount?.rib?.fileLocation.replaceAll(
                              '/',
                              '§'
                            )}`
                          : undefined
                      }
                      label="RIB"
                      underline
                    />
                    <></>
                  </Row>
                </>
              ) : (
                <Text>
                  L'établissement ne possède aucune information bancaire.
                </Text>
              )}
            </Section>
          </Box>
          <Box width={'50%'}>
            {data && (
              <FileContainer>
                <iframe
                  //@ts-ignore
                  src={data && (data as any)?.config?.url}
                  frameBorder="0"
                  title="iframe"
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 80px)',
                    maxHeight: 800,
                  }}
                ></iframe>
              </FileContainer>
            )}
          </Box>
        </Flex>
      </Box>
      <Flex
        mt={10}
        width={'100%'}
        display={'inline-flex'}
        justifyContent={'flex-end'}
      >
        <Flex>
          <Button isLoading={isLoading} onClick={onValidateRequest} mr={10}>
            Valider
          </Button>
          <Button
            isLoading={isLoading}
            onClick={() =>
              showRejectModal({
                title: 'Refuser la demande',
                subtitle: 'Veuilliez indiquer le motif de refus.',
                onRejectValidation: data => onRejectValidation(data),
              })
            }
          >
            Refuser
          </Button>
        </Flex>
      </Flex>
    </ModalFrame>
  );
};

export const showValidateBankAccountModal = create<Props>(
  ValidateBankAccountModal
);
