/* eslint-disable react-hooks/rules-of-hooks */
import {
  EChangeOwnerType,
  EInvoiceStatus,
  EInvoiceType,
  IFile,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import { useHistory, useLocation } from 'react-router-dom';
import {
  buildOneLineAddress,
  getEstablishmentName,
  getFullName,
  getHumanDate,
  isCreditNoteInvoice,
  isMileStoneInvoice,
  isProviderInvoice,
  managerExtractVerifyRibNeedWarning,
  useEstablishmentFindOne,
} from '@commons';
import { Section } from 'components/Section';
import { HistoryList } from 'components/lists/HistoryList';
import {
  ALink,
  Box,
  CheckSwitch,
  Container,
  DatePickerControlled,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  StaticSelectControlled,
  Status,
  Tab,
  Table,
  Text,
} from 'components/ui';
import { CloseIcon, EuroIcon, LockIcon } from 'components/ui/icons';
import { printFormat } from 'iban';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Theme } from 'styles';
import { columns } from '../HistoryListData';
import { lowerCase } from 'lodash';

const Footer = styled.div`
  padding: 20px;
  background-color: #fff;
`;
const Header = styled.div`
  background-color: #fff;
  padding: 20px;
`;
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;

  background-color: rgb(251, 250, 250);
  z-index: 90;
`;
export type FormValues = IJoinedInvoice & { invoiceFile: any };
type EditTimeSpentFormProps = {
  invoice?: IJoinedInvoice & { invoiceFile: any };
  title: string;
  switchButtons: React.ReactNode;
  onChangeInvoice?: (index: number) => void;
  from?: 'MISSION' | 'INVOICES';
};

export const EditTimeSpentFormFromGescom = ({
  invoice,
  title,
  switchButtons,
  onChangeInvoice,
  from,
}: EditTimeSpentFormProps) => {
  const [invoiceFile, setInvoiceFile] = React.useState<IFile | null>(
    invoice?.invoiceFile ? invoice?.invoiceFile : null
  );

  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const history = useHistory();
  const location = useLocation();
  const {
    reset,
    watch,
    control,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...invoice,
      totalBeforeTaxes: invoice?.totalBeforeTaxes
        ? Number(invoice?.totalBeforeTaxes?.toFixed(2))
        : 0,
      totalWithTaxes: invoice?.totalWithTaxes
        ? Number(invoice?.totalWithTaxes?.toFixed(2))
        : 0,
      vat: invoice?.vat ? Number(invoice?.vat?.toFixed(2)) : 0,
    },
  });
  const invoiceType = invoice?.invoiceType;
  const invoiceFormUuuid = watch('uuid');

  const { data: establishment } = useEstablishmentFindOne(
    invoice?.estProvider?.uuid
  );
  const handleRedirect = (history: any) => {
    //@ts-ignore
    const invoicePathFrom = location?.state?.invoicePathFrom;
    if (!history || !invoice) {
      return;
    }
    const providerOrClientPath = isProviderInvoice(
      invoice?.invoiceType as EInvoiceType
    )
      ? 'provider'
      : 'customer';
    const timeSpentOrCreditNote = isCreditNoteInvoice(
      invoice?.invoiceType as EInvoiceType
    )
      ? 'credit-note'
      : 'time-spent';
    const awaitStatus = [
      EInvoiceStatus?.TO_BE_FILLED,
      EInvoiceStatus?.TO_BE_SUBMITTED,
    ];
    const isAwaitingStatus = awaitStatus?.includes(
      invoice?.status as EInvoiceStatus
    );
    const isToBeValidatedStatus =
      invoice?.status === EInvoiceStatus.TO_BE_VALIDATED;
    let pathStatus = 'all';
    if (providerOrClientPath === 'provider') {
      if (timeSpentOrCreditNote === 'time-spent') {
        if (isAwaitingStatus) {
          pathStatus = 'awaiting';
        }
        if (isToBeValidatedStatus) {
          pathStatus = 'to-be-validated';
        }
      }
    }
    if (providerOrClientPath === 'customer') {
      if (timeSpentOrCreditNote === 'time-spent') {
        pathStatus = lowerCase(invoice?.status);
      }
    }
    const defaultPath = `/orders/invoices/${providerOrClientPath}/${timeSpentOrCreditNote}/${pathStatus}`;

    switch (invoicePathFrom) {
      case 'CUSTOMER_ESTABLISHMENTS':
        history.push(
          `/clients/establishments/${invoice?.estCustomer?.uuid}/invoices`
        );
        break;
      case 'PROVIDER_ESTABLISHMENTS':
        history.push(
          `/providers/establishments/${invoice?.estProvider?.uuid}/invoices`
        );
        break;
      case 'MISSION_CREDITNOTE':
        history.push(
          `/delivery/missions/${invoice?.mission?.reference}/credit-note`
        );
        break;
      case 'MISSION_INVOICE':
        history.push(
          `/delivery/missions/${invoice?.mission?.reference}/invoice`
        );
        break;
      default:
        // no invoicePathFrom
        history.push(defaultPath);
    }
  };
  const copyOfReceivedSageInvoice = invoice?.sage?.copyOfReceivedSageInvoice;
  const invoiceStatus = invoice?.status;
  const getStatusInfo = () => {
    const info = {
      text: '',
      color: '',
    };
    if (invoiceStatus === EInvoiceStatus.VALIDATED) {
      info.text = `Validée le : ${copyOfReceivedSageInvoice?.document?.createDate?.toLocaleDateString()}`;
      info.color = 'success';
      return info;
    }
    if (invoiceStatus === EInvoiceStatus.PAID) {
      const paidDate = invoice?.sage?.paymentDetails?.paymentDate
        ? `- Virement émis le : ${invoice?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()}`
        : '';
      info.text = `Payée ${paidDate}`;
      info.color = 'success';
      return info;
    }
    return false;
  };
  const isEditable = false;
  const statusInfo = getStatusInfo();
  useEffect(() => {
    if (invoice?.uuid !== invoiceFormUuuid) {
      reset(invoice);
      if (invoice?.invoiceFile?.fileLocation !== invoiceFile?.fileLocation) {
        setInvoiceFile(invoice?.invoiceFile);
      }
    }
  }, [invoice, invoiceFormUuuid]);
  const gescomActionTexte =
    copyOfReceivedSageInvoice?.action === 'CREATE'
      ? 'Créée  depuis GESCOM'
      : 'Modifiée  depuis GESCOM';
  return (
    <Modal>
      <Header>
        <Container>
          <Flex justifyContent="center">
            <Text variant="h1">{title}</Text>
          </Flex>
          <Flex justifyContent="center" alignItems={'center'}>
            <LockIcon fontSize={20} />
            <Text ml={'5px'} variant="h2">
              {gescomActionTexte}
            </Text>
          </Flex>
        </Container>
      </Header>
      <Flex mb={10}>
        <Tab
          onClick={() => setFormSelected('INFORMATIONS')}
          isSelected={formSelected === 'INFORMATIONS'}
          variant="secondary"
          fontSize={12}
        >
          Informations
        </Tab>
        <Tab
          onClick={() => setFormSelected('HISTORY')}
          isSelected={formSelected === 'HISTORY'}
          variant="secondary"
          fontSize={12}
        >
          Historique
        </Tab>
      </Flex>
      {formSelected === 'HISTORY' && (
        <Flex
          flexWrap={'wrap'}
          overflow={'hidden'}
          height={'calc(100vh - 90px)'}
        >
          <Box
            width={3 / 5}
            flex={1}
            overflow={'scroll'}
            height={'calc(100vh - 180px)'}
            pl={'20px'}
            pr={'20px'}
            pt={'20px'}
          >
            <HistoryList
              columns={columns}
              filterObject={{
                ownerId: invoice?.uuid,
                ownerType: EChangeOwnerType?.INVOICE,
              }}
            />
            <Box width={1 / 1} position={'fixed'} bottom={0} right={'5px'}>
              <Footer>
                <Flex justifyContent="flex-end" alignItems="center">
                  <Link
                    onClick={() => {
                      handleRedirect(history);
                    }}
                    iconLeft={
                      <CloseIcon height={15} style={{ marginTop: 7 }} />
                    }
                  >
                    Quitter
                  </Link>
                </Flex>
              </Footer>
            </Box>
          </Box>
        </Flex>
      )}
      {formSelected === 'INFORMATIONS' && (
        <Flex
          flexWrap={'wrap'}
          overflow={'hidden'}
          height={'calc(100vh - 90px)'}
        >
          <Box
            width={3 / 5}
            flex={1}
            overflow={'scroll'}
            height={'calc(100vh - 200px)'}
            pl={'20px'}
            pr={'20px'}
            pt={'20px'}
          >
            <Section
              mt={20}
              variant="h2"
              title={
                <Flex>
                  Facture{' '}
                  {statusInfo && (
                    //@ts-ignore
                    <Status ml={20} variantColor={statusInfo.color}>
                      {statusInfo.text}
                    </Status>
                  )}
                </Flex>
              }
            >
              <Flex justifyContent="space-between">
                <Box width={3 / 12}>
                  <FormControl
                    required
                    label={'N° de facture fournisseur'}
                    errorMessage={errors?.providerInvoiceRef?.message}
                  >
                    <Input
                      isDisabled={!isEditable}
                      isFullWidth
                      value={
                        copyOfReceivedSageInvoice?.document?.providerInvoiceRef
                      }
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box width={3 / 12}>
                  <FormControl required label={'N° facture Gescom'}>
                    <Input
                      value={copyOfReceivedSageInvoice?.documentId}
                      isDisabled
                      isFullWidth
                    />
                  </FormControl>
                </Box>
                <Box width={3 / 12}>
                  <FormControl
                    required
                    label={'Date de facture'}
                    errorMessage={errors?.invoiceDate?.message}
                  >
                    <DatePickerControlled
                      disabled={!isEditable}
                      control={control}
                      required
                      valueName="selected"
                      name="invoiceDate"
                      rules={{
                        required: 'Ce champs est requis',
                      }}
                    />
                  </FormControl>
                </Box>
              </Flex>
            </Section>
            <Section mt={20} variant="h2" title={'Etablissement fournisseur'}>
              <Flex>
                <Box width={3 / 10}>
                  <FormLabel>Raison sociale</FormLabel>
                  <Text variant="p">
                    {getEstablishmentName(invoice?.estProvider, 80)}
                  </Text>
                </Box>
                <Box width={3 / 10}>
                  <FormLabel>N° SIRET / IDENTIFIER</FormLabel>
                  <Text variant="p">
                    {invoice?.estProvider?.siret ||
                      invoice?.estProvider?.identifier}
                  </Text>
                </Box>
                <Box width={3 / 10}>
                  <FormLabel>adresse de l'établissement</FormLabel>
                  <Text variant="p">
                    {buildOneLineAddress(invoice?.estProvider?.address)}
                  </Text>
                </Box>
              </Flex>
              <Flex mt={20}>
                <Box width={3 / 10}>
                  <FormLabel>Coordonnées bancaires</FormLabel>
                  <Text variant="p">
                    IBAN: {establishment?.provider?.bankAccount?.iban || 'N/A'}
                  </Text>
                </Box>
                <Box width={3 / 10} mt={20}>
                  <Flex>
                    {' '}
                    IBAN:&nbsp;
                    {establishment?.provider?.bankAccount?.rib?.fileName ? (
                      <ALink
                        style={{ color: '#0000EE' }}
                        target="_blank"
                        href={`/file/${establishment?.provider?.bankAccount?.rib?.fileLocation?.replaceAll(
                          '/',
                          '§'
                        )}`}
                      >
                        {establishment?.provider?.bankAccount?.rib?.fileName}
                      </ALink>
                    ) : (
                      'N/A'
                    )}
                  </Flex>
                </Box>
                <Box width={3 / 10} mt={20}>
                  <Text variant="p">
                    BIC: {establishment?.provider?.bankAccount?.bic || 'N/A'}
                  </Text>
                </Box>
              </Flex>
              <Flex>
                <Box width={3 / 10}>
                  <Text variant="p">
                    Nom de la banque :{' '}
                    {establishment?.provider?.bankAccount?.bankName || 'N/A'}
                  </Text>
                </Box>
                <Box width={3 / 10} />
                <Box width={3 / 10}>
                  <Text variant="p">
                    Titulaire du compte:{' '}
                    {establishment?.provider?.bankAccount?.owner || 'N/A'}
                  </Text>
                </Box>
              </Flex>
              <Flex>
                <Box width={10 / 10}>
                  {managerExtractVerifyRibNeedWarning(
                    invoice?.invoiceFileMetaData
                  ) && (
                    <Box
                      mt={10}
                      color={Theme?.colors?.warning?.default}
                      padding={'20px'}
                      border={`1px solid ${Theme?.colors?.warning?.default}`}
                      backgroundColor="rgba(253,238,239,0.5)"
                      borderRadius={'5px'}
                    >
                      <Text color={Theme?.colors?.warning?.default}>
                        Attention suite aux vérifications effectuées le{' '}
                        {getHumanDate(
                          invoice?.invoiceFileMetaData?.extractedAt as Date
                        )}{' '}
                        nous avons détécté les anomalies suivantes :
                      </Text>
                      <ul>
                        {!invoice?.invoiceFileMetaData?.hasRibOnSage && (
                          <Text
                            variant="li"
                            color={Theme?.colors?.warning?.default}
                          >
                            L'établissement n'a pas de RIB enregistré dans la
                            GESCOM
                          </Text>
                        )}
                        {invoice?.invoiceFileMetaData?.hasRibOnSage &&
                          !invoice?.invoiceFileMetaData?.ribMatches && (
                            <Text
                              variant="li"
                              color={Theme?.colors?.warning?.default}
                            >
                              L'IBAN indiqué sur la facture pdf (
                              {printFormat(
                                invoice?.invoiceFileMetaData
                                  ?.extractedIban as string
                              )}
                              ) ne correspond pas à l'IBAN enregistré dans la
                              GESCOM ({invoice?.invoiceFileMetaData?.ibanHint} )
                            </Text>
                          )}
                      </ul>
                    </Box>
                  )}
                </Box>
              </Flex>
            </Section>
            <Section mt={20} variant="h2" title={'Mission'}>
              <Flex>
                <Box width={3 / 10}>
                  <FormLabel>Référence</FormLabel>
                  <Text variant="p">{invoice?.mission?.displayReference}</Text>
                </Box>
                <Box width={3 / 10}>
                  <FormLabel>Structure de Facturation</FormLabel>
                  <Text variant="p">
                    {invoice?.mission?.billingInformation?.structure}
                  </Text>
                </Box>
                {invoice?.month &&
                  !isMileStoneInvoice(invoiceType as EInvoiceType) && (
                    <Box width={3 / 10}>
                      <FormLabel>Mois</FormLabel>
                      <Text variant="p">
                        {invoice?.month &&
                          new Date(invoice?.month).getUTCMonth() + 1}{' '}
                        /{' '}
                        {invoice?.month &&
                          new Date(invoice?.month).getUTCFullYear()}
                      </Text>
                    </Box>
                  )}
                <Box width={3 / 10}>
                  <FormLabel>Intervenant</FormLabel>
                  <Text variant="p">{getFullName(invoice?.contractor)}</Text>
                </Box>
              </Flex>
            </Section>
            <Divider />
            <Section mt={20} variant="h2" title={'Lignes de facture'}>
              <Table
                items={copyOfReceivedSageInvoice?.document?.lines}
                total={copyOfReceivedSageInvoice?.document?.lines?.length}
                perPage={copyOfReceivedSageInvoice?.document?.lines?.length}
                columns={[
                  {
                    key: 'designation',
                    text: 'Designation',
                    render: (item: any) => item?.designation,
                  },
                  {
                    key: 'quantity',
                    text: 'Prix HT unitaire',
                    render: (item: any) => item?.priceRu + '€',
                  },
                  {
                    key: 'quantity',
                    text: 'Quantité',
                    render: (item: any) => item?.quantity,
                  },
                  {
                    key: 'unit',
                    text: 'Unité',
                    render: (item: any) => item?.unit || 'Jour(s)',
                  },
                  {
                    key: 'amount',
                    text: 'total',
                    render: (item: any) => item?.amount + '€',
                  },
                ]}
              />
            </Section>

            <Flex justifyContent="flex-end">
              <Box width={6 / 10} />
              <Box width={3 / 10}>
                <FormControl label={'Montant Net (HT)'}>
                  <Input
                    value={invoice?.totalBeforeTaxes}
                    isDisabled
                    isFullWidth
                    type="number"
                    maxDecimal={2}
                    step={'0.01'}
                    icon={<EuroIcon />}
                  />
                </FormControl>
              </Box>
            </Flex>
            <Divider />
            <Flex mt={20} justifyContent="space-between">
              <Box width={2 / 10} mt={30}>
                <CheckSwitch
                  isDisabled={true}
                  id="isVat"
                  {...register('isVat')}
                >
                  <Text variant="h3">TVA</Text>
                </CheckSwitch>
              </Box>
              <Box width={2 / 10}>
                <FormControl label={'N° de TVA'}>
                  <Input
                    isDisabled={true}
                    isFullWidth
                    {...register('vatNumber')}
                    maxDecimal={2}
                    step={'0.01'}
                  />
                </FormControl>
              </Box>
              <Box width={2 / 10}>
                <FormControl required label={'% TVA'}>
                  <StaticSelectControlled
                    isClearable={false}
                    control={control}
                    name="vatRate"
                    isDisabled={true}
                    options={[
                      { value: 20, label: '20%' },
                      { value: 10, label: '10%' },
                      { value: 5.5, label: '5,5%' },
                      { value: 2.1, label: '2,1%' },
                      { value: 0, label: '0%' },
                    ]}
                    placeholder="% TVA"
                  />
                </FormControl>
              </Box>
              <Box width={2 / 10}>
                <FormControl label={'Montant TVA'}>
                  <Input
                    isDisabled
                    isFullWidth
                    {...register('vat')}
                    maxDecimal={2}
                    step={'0.01'}
                    type="number"
                    icon={<EuroIcon />}
                  />
                </FormControl>
              </Box>
            </Flex>
            <Divider />
            <Flex justifyContent="flex-end">
              <Box width={6 / 10} />
              <Box width={3 / 10} mb={20}>
                <FormControl label={'Montant Total TTC'}>
                  <Input
                    isDisabled
                    isFullWidth
                    {...register('totalWithTaxes')}
                    type="number"
                    maxDecimal={2}
                    step={'0.01'}
                    icon={<EuroIcon />}
                    //value={totalWithTaxes}
                  />
                </FormControl>
              </Box>
            </Flex>
          </Box>
          <Box width={1 / 1} position={'fixed'} bottom={0}>
            <Footer>
              <Flex justifyContent="flex-end" alignItems="center">
                {switchButtons && switchButtons}
                <Link
                  mr={10}
                  onClick={() => {
                    handleRedirect(history);
                  }}
                  iconLeft={<CloseIcon height={15} style={{ marginTop: 7 }} />}
                >
                  Quitter
                </Link>
              </Flex>
            </Footer>
          </Box>
        </Flex>
      )}
    </Modal>
  );
};
