import {
  useEstablishmentFindOne,
  useEstablishmentUpdateOne,
  checkAllStrcutureCreatedInGescom,
  establishmentUseIban,
  checkUserHasRole,
  useMe,
  useAdminValidationFindMany,
  getHumanDate,
} from '@commons';
import AddBloc from 'components/AddBloc';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { showSignatoryInformationModal } from 'components/modals/SignatoryInformationModal';
import { showOtherSignersModal } from 'components/modals/providers/OtherSignersModal';
import {
  Flex,
  Table,
  Box,
  Link,
  Text,
  FormLabel,
  Row,
  Status,
  ALink,
  CustomToolTip,
} from 'components/ui';
import { EditIcon } from 'components/ui/icons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { useParams } from 'react-router-dom';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { getTradeNameSafe } from '@commons';
import { AddIcon } from 'components/ui/icons';
import { showAddEstablishmentProviderGescomModal } from 'components/modals/AddEstablishmentProviderGescomModal';
import { showAddPaiementEstaProviderModal } from 'components/modals/AddPaiementEstaProviderModal';
import { printFormat } from 'iban';
import { SignOfficerCard } from 'components/cards/SignOfficerCard';
import {
  ESignOfficerType,
  EValidationStatus,
  EValidationType,
  ISigner,
} from '@freelancelabs/teoreme-commons';
import { columnsOtherSigners } from './TableConfig';
import { showDialogModal } from 'components/modals/DialogModal';
import { queryClient } from '@commons';
import { Theme } from 'styles';

export const EstablishmentProviderInformation = () => {
  const { id } = useParams<{ id: string }>();
  const { me } = useMe();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const { data: establishment } = useEstablishmentFindOne(id);
  const { data: validations } = useAdminValidationFindMany({
    filterObject: {
      status: { $in: [EValidationStatus.PENDING, EValidationStatus.REJECTED] },
      type: EValidationType.PROVIDER_BANK_ACCOUNT,
      ownerId: id,
    },
    limit: 10,
    skip: 0,
    sort: 'createdAt',
  });
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'établissements',
        path: '/providers/establishments',
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: '/providers/establishments/' + establishment?.uuid || '',
      },
      {
        label: 'information',
        path: `/providers/establishments/${
          establishment?.uuid || ''
        }/information`,
      },
    ]
  );
  const removeOtherSigner = async (signer: ISigner) => {
    try {
      await showDialogModal({
        title: 'Supprimer le signataire',
        text: 'Êtes-vous sûr de vouloir supprimer le signataire ?',
        confirmLabel: 'Supprimer',
        cancelLabel: 'Ne pas Supprimer',
        beforeValidation: async () => {
          await updateEstablishment({
            uuid: establishment?.uuid as string,
            establishment: {},
            //@ts-ignore
            removeOtherSigners: [signer?.email],
          });
          queryClient?.refetchQueries({
            queryKey: [establishment?.uuid as string],
          });
        },
      });
    } catch (e) {
      //
    }
  };
  if (!establishment) return null;
  const { signOfficerType, delegatedSignOfficer, signOfficer } =
    establishment?.provider;
  const { sage, bankAccount } = establishment?.provider;
  const checkSageData = checkAllStrcutureCreatedInGescom(sage);
  const otherSigners = establishment?.provider?.otherSigners || [];

  const getValidationRequestStatus = () => {
    if (validations && validations?.validationRequests?.length > 0) {
      let request = validations?.validationRequests?.[0];
      if (request?.status === EValidationStatus.PENDING) {
        return (
          <ALink href="/providers/validate-bank-account/pending">
            <Status variantColor="warning">
              Une demande de modification à valider depuis le :{' '}
              {getHumanDate(request?.createdAt as Date)}
            </Status>
          </ALink>
        );
      }
      if (request?.status === EValidationStatus.REJECTED) {
        return (
          <ALink href="/providers/validate-bank-account/pending">
            <CustomToolTip
              id={request?.uuid}
              cursor="pointer"
              color={Theme?.colors?.error?.default}
              text={request?.rejectReason}
            >
              <Status variantColor="error">
                Une demande refusée le :{' '}
                {getHumanDate(request?.rejectedAt as Date)}
              </Status>
            </CustomToolTip>
          </ALink>
        );
      }
    }
  };
  const havePermissionToEditBankAccount = () => {
    if (
      checkUserHasRole(me, 'MANAGER_RIB') &&
      !validations?.validationRequests?.find(
        (vr: { status: EValidationStatus }) =>
          vr?.status === EValidationStatus.PENDING
      )
    ) {
      return true;
    }
    return false;
  };
  return (
    <Box>
      <Box display={'inline-flex'}>
        <Text variant="h2" mb={20}>
          Identification de l'établissement
        </Text>
        {!checkSageData?.isReady ? (
          <Link
            ml={3}
            iconLeft={<EditIcon />}
            mt={'5px'}
            onClick={() =>
              showAddEstablishmentProviderGescomModal({
                establishment: establishment as any,
              })
            }
          >
            {`Créer dans GESCOM (
            ${checkSageData?.missingStructure?.map(
              structure => `${structure} `
            )} )`}
          </Link>
        ) : (
          <></>
        )}
      </Box>
      <Box mb="10px">
        <EstablishmentStructuredCard
          checkSage
          forRole={'PROVIDER'}
          establishment={establishment}
        />
      </Box>
      <Flex mb="20px" alignItems={'center'}>
        <Box>
          <Text variant="h2" mt="10px">
            Coordonnées bancaires {getValidationRequestStatus()}
          </Text>
        </Box>
        {bankAccount && (
          <Box mt={20} ml={20}>
            {havePermissionToEditBankAccount() && (
              <Link
                iconLeft={<EditIcon />}
                onClick={() =>
                  showAddPaiementEstaProviderModal({
                    id: establishment.uuid,
                  })?.then(() =>
                    queryClient?.refetchQueries({
                      queryKey: ['admin-validation-search'],
                    })
                  )
                }
              >
                Modifier
              </Link>
            )}
          </Box>
        )}
      </Flex>

      {!bankAccount ? (
        <AddBloc>
          <Link
            isDisabled={!havePermissionToEditBankAccount()}
            onClick={() =>
              havePermissionToEditBankAccount()
                ? showAddPaiementEstaProviderModal({
                    id: establishment.uuid,
                  })?.then(() =>
                    queryClient?.refetchQueries({
                      queryKey: ['admin-validation-search'],
                    })
                  )
                : false
            }
            iconLeft={
              <AddIcon
                fill={
                  !havePermissionToEditBankAccount()
                    ? Theme?.colors?.grey?.default
                    : Theme?.colors?.primary?.default
                }
              />
            }
          >
            Ajouter des coordonnées bancaires
          </Link>
        </AddBloc>
      ) : (
        <Box mb={20}>
          {establishmentUseIban(establishment) ? (
            <>
              <Row spacing={20}>
                <Box>
                  <FormLabel>IBAN</FormLabel>
                  <Text mb={20} variant="p">
                    {bankAccount?.iban && printFormat(bankAccount?.iban, ' ')}
                  </Text>
                </Box>
                <Box>
                  <FormLabel>BIC</FormLabel>
                  <Text mb={20} variant="p">
                    {bankAccount.bic}
                  </Text>
                </Box>
              </Row>
              <Row spacing={20}>
                <Box>
                  <FormLabel>Nom de la banque</FormLabel>
                  <Text mb={20} variant="p">
                    {bankAccount.bankName}
                  </Text>
                </Box>
                <Box>
                  <FormLabel>Titulaire du compte</FormLabel>
                  <Text mb={20} variant="p">
                    {bankAccount.owner}
                  </Text>
                </Box>
              </Row>
              <Row spacing={20}>
                <Box>
                  <FormLabel>Relevé d'identité bancaire</FormLabel>
                  {bankAccount?.rib?.fileLocation ? (
                    <Text variant="p">
                      <Link
                        mr={10}
                        onClick={() =>
                          showDisplayPdfModal({
                            fileLocation: bankAccount?.rib?.fileLocation || '',
                            fileName: `${bankAccount?.rib?.fileName}.pdf`,
                            layer: 200,
                          })
                        }
                      >
                        {`${bankAccount?.rib?.fileName}`}
                      </Link>
                    </Text>
                  ) : (
                    'N/A'
                  )}
                </Box>
                <Box></Box>
              </Row>
            </>
          ) : (
            <Box width={1 / 1}>
              <Box>
                <FormLabel>Relevé d'identité bancaire</FormLabel>
                {bankAccount?.rib?.fileLocation ? (
                  <Text variant="p">
                    <Link
                      mr={10}
                      onClick={() =>
                        showDisplayPdfModal({
                          fileLocation: bankAccount?.rib?.fileLocation || '',
                          fileName: `${bankAccount?.rib?.fileName}.pdf`,
                          layer: 200,
                        })
                      }
                    >
                      {`${bankAccount?.rib?.fileName}`}
                    </Link>
                  </Text>
                ) : (
                  'N/A'
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Flex mb="20px" alignItems={'center'}>
        <Box>
          <Text variant="h2" mt="10px">
            Signataire du contrat cadre
          </Text>
        </Box>
        {signOfficer && (
          <Box mt={20} ml={20}>
            <Link
              iconLeft={<EditIcon />}
              onClick={() =>
                showSignatoryInformationModal({ establishment: establishment })
              }
            >
              Modifier
            </Link>
          </Box>
        )}
      </Flex>
      {signOfficer ? (
        <SignOfficerCard
          establishment={establishment}
          signOfficer={signOfficer as ISigner}
          signOfficerType={signOfficerType as ESignOfficerType}
          delegatedSignOfficer={delegatedSignOfficer}
        />
      ) : (
        <AddBloc>
          <Link
            data-cy="add-signatory"
            iconLeft={<AddIcon />}
            onClick={() =>
              showSignatoryInformationModal({ establishment: establishment })
            }
          >
            Ajouter les informations du signataire
          </Link>
        </AddBloc>
      )}
      <Box>
        <Flex mb="20px" alignItems={'center'}>
          <Text variant="h2" mt="10px">
            Signataires des bons de commande
          </Text>
          {otherSigners.length > 0 && (
            <Box ml={20} mt={'10px'}>
              <Link
                iconLeft={<EditIcon />}
                onClick={() =>
                  showOtherSignersModal({ establishment: establishment })?.then(
                    action => {
                      if (action) {
                        queryClient?.refetchQueries({ queryKey: [id] });
                      }
                    }
                  )
                }
              >
                Créer un signataire
              </Link>
            </Box>
          )}
        </Flex>
        {otherSigners.length > 0 ? (
          <Table
            mt={20}
            columns={columnsOtherSigners(
              otherSigners,
              establishment,
              removeOtherSigner
            )}
            items={otherSigners as any}
            total={0}
            perPage={2000}
            isLoading={false}
          />
        ) : (
          <AddBloc>
            <Link
              data-cy="add-signatory"
              iconLeft={<AddIcon />}
              onClick={() =>
                showOtherSignersModal({ establishment: establishment })?.then(
                  async action => {
                    queryClient?.refetchQueries({ queryKey: [id] });
                  }
                )
              }
            >
              Ajouter les informations du signataire des bons de commande
            </Link>
          </AddBloc>
        )}
      </Box>
      <Box height={20} />
    </Box>
  );
};

//export default EstablishmentsDetails;
